.home-page {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.home-page-cards {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-page-card {
  margin: 1rem;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
}
