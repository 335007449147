.side-bar {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 70px;
  height: 100%;
  transition: width 0.3s ease-in-out;
}

.side-bar.side-bar-open-true {
  width: 350px;
}

.menu-item:hover {
  background-color: #ddfe0767;
}

.menu-item {
  cursor: pointer;
  padding: 5px 5px;
  margin-bottom: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.menu-data {
  display: inline-flex;
  vertical-align: middle;
  margin-bottom: 0;
}

.menu-item.menu-heading {
  margin-bottom: 30px;
}

.menu-data.menu-name {
  padding-left: 15px;
}

.menu-item.menu-item-background-true {
  background-color: #dcfe07;
}

.drop-down {
  padding-left: 10px;
  transition: transform 0.3s;
  justify-content: flex-end;
}

.sub-menu {
  margin-left: 15px;
}
