.abbreviation-single-input {
    margin-top: 20px;
    width: 50%;
}
  
.abbreviation-single-input-button {
    margin-top: 20px;
}
  
.abbreviation-single-input-fieldset {
    margin-top: 20px;
}
  
.abbreviation-mappings {
    margin-top: 20px;
}
