.sku-food-gpt-text-label {
  margin-top: 20px;
  margin-right: 10px;
}

.sku-food-gpt-container {
  display: flex;
  justify-content: space-between;
}

.sku-food-gpt-column {
  flex: 1;
  margin-right: 10px; /* Adjust the spacing between columns as needed */
}

.sku-food-gpt-column:last-child {
  margin-right: 0;
}
