.breadcrumbs {
  padding-bottom: 50px;
}

.text-h2 {
  margin-top: 20px;
}

.autocomplete-drop-down {
  max-width: 50%;
  padding: 0;
  margin-bottom: 20px;
}

.loading-true {
    opacity: 0.5;
}

.table-wrapper {
  display: flex;
}

.data-table {
  width: 50%;
  overflow-x: auto;
}

.data-table.left {
  padding-right: 10px;
}

.data-table.right {
  padding-left: 10px;
}

.table-pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.table-row {
  overflow-x: auto;
}

.cancel-button {
  display: flex;
  justify-content: flex-end;
}

.pagination-button-display-true {
  cursor: pointer;
}

.pagination-button-display-false {
  opacity: 0.2;
}

.pagination-text {
  padding-right: 50px;
}

.pagination-buttons {
  padding-right: 20px;
}