.fsa-single-input {
  margin-top: 20px;
  width: 50%;
}

.fsa-single-input-button {
  margin-top: 20px;
}

.fsa-single-input-fieldset {
  margin-top: 20px;
}

.generate-attributes-single-text-label {
  margin-top: 20px;
  margin-right: 10px;
}

.generate-attributes-single-loading-text {
  margin-top: 20px;
}
