.sku-single-input {
  margin-top: 20px;
  width: 50%;
}

.sku-single-input-button {
  margin-top: 20px;
}

.sku-single-input-table {
  width: 150%;
  margin-top: 20px;
}

.sku-single-text-label {
  margin-top: 20px;
  margin-right: 10px;
}
